<template>
    <section class="lg:container searchbox-container mx-auto">
        <!-- TABS -->
        <div v-if="blok && blok.Tabs" class="tabs">
            <template v-for="tab in blok.Tabs" :key="tab._uid">
                <a
                    href="#"
                    class="tab"
                    :class="{ [tab?.Type]: true, active: tab?.Type === activeTabState }"
                    @click.prevent="setSelectedTab(tab?.Type)"
                >
                    <div class="title">
                        <div class="icons">
                            <img
                                v-if="tab?.Type === 'Parking'"
                                src="~/assets/images/icon-parking.webp"
                                alt="airport-parking-icon"
                                loading="eager"
                                densities="x1"
                            />
                            <img
                                v-if="tab?.Type === 'HotelWithParking'"
                                src="~/assets/images/icon-hotel.webp"
                                alt="airport-parking-with-hotel-icon"
                                loading="eager"
                                densities="x1"
                            />
                            <img
                                v-if="tab?.Type === 'Lounge'"
                                src="~/assets/images/icon-lounge.webp"
                                alt="airport-lounge-icon"
                                loading="eager"
                                densities="x1"
                            />
                        </div>
                        <span>{{ parkingType(tab.Title) }}</span>
                    </div>
                    <div class="subtitle">{{ parkingType(tab.Subtitle) }}</div>
                </a>
            </template>
        </div>

        <!-- FORMS -->
        <div class="forms">
            <template v-for="tab in blok.Tabs" :key="tab._uid">
                <component
                    :is="FormComponents[tab?.Type]"
                    v-if="searchStore.activeTab == tab?.Type"
                    :tabs="tab"
                    :loading="loading"
                    :airport-list="airportsList"
                    :default-location="blok.SPSDefault"
                    :default-promo="blok.Promo"
                    :show-promo="blok.ShowPromo"
                    @submit-search="submitForm"
                />
            </template>
        </div>
    </section>
</template>

<script setup lang="ts">
import HotelWithParkingComponent from './Form/HotelWithParking.vue';
import ParkingComponent from './Form/Parking.vue';
import LoungeComponent from './Form/Lounge.vue';
import { useSearchStore } from '../../../../apps/_base/store/search';

import type { ISearchTrip } from '~ui/types/components/Searchbox';
import type { IAirportEntity } from '~ui/types';

const { blok } = defineProps<{ blok: any; loading: boolean }>();
const emit = defineEmits<{
    (e: 'submitStoryblok', body: any): void;
}>();

const config: any = useRuntimeConfig();
const route: any = useRoute();
const searchStore: any = useSearchStore();
const activeTabState: any = useState('activeTab', () => searchStore.activeTab ?? blok.DefaultTab);

watch(searchStore, (store) => {
    if (activeTabState.value) activeTabState.value = store.activeTab;
});

const FormComponents: any = {
    HotelWithParking: HotelWithParkingComponent,
    Parking: ParkingComponent,
    Lounge: LoungeComponent,
};

const submitForm = (body: ISearchTrip) => {
    emit('submitStoryblok', body);
};

if (new Date(searchStore.dates.start.date) < new Date()) {
    searchStore.update({ dates: { start: '', end: '' } });
}

searchStore.updateIsCruisePort(blok.DefaultCruiseport);
searchStore.updatePromo(blok.ShowPromo ? blok.Promo : '');

if (blok.DefaultTab !== 'None') {
    searchStore.updateActiveTab(blok.DefaultTab);
}

onMounted(() => {
    addEventListener('hashchange', () => {
        if (location.hash) {
            switchTabFromHash();
        }
    });
    if (location.hash) {
        switchTabFromHash();
    }
});

function setSelectedTab(tab: string) {
    searchStore.updateActiveTab(tab);
}

function parkingType(text: string) {
    return searchStore.isCruiseport ? text.replace('airport', 'cruise port').replace('Airport', 'Cruise Port') : text;
}

function switchTabFromHash() {
    if (location.hash === '#search-parking') {
        setSelectedTab('Parking');
    }
    if (location.hash === '#search-hotel-parking') {
        setSelectedTab('HotelWithParking');
    }
    if (location.hash === '#search-lounges') {
        setSelectedTab('Lounge');
    }
    if (location.hash.includes('#search')) {
        const noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }
}

const airportsList = [
    { label: 'Select Airport', code: '', value: 0, disabled: false },
    { label: 'Aberdeen', code: 'ABZ', value: 18, disabled: false },
    { label: 'Belfast City', code: 'BHD', value: 33, disabled: false },
    { label: 'Belfast International', code: 'BFS', value: 11, disabled: false },
    { label: 'Birmingham', code: 'BHX', value: 12, disabled: false },
    { label: 'Bournemouth', code: 'BOH', value: 30, disabled: false },
    { label: 'Bristol', code: 'BRS', value: 3, disabled: false },
    { label: 'Cardiff', code: 'CWL', value: 13, disabled: false },
    { label: 'Doncaster', code: 'DSA', value: 21, disabled: false },
    { label: 'Dover Port', code: 'DOV', value: 24, disabled: false },
    { label: 'East Midlands', code: 'EMA', value: 4, disabled: false },
    { label: 'Edinburgh', code: 'EDI', value: 5, disabled: false },
    { label: 'Exeter', code: 'EXT', value: 19, disabled: false },
    { label: 'Gatwick', code: 'LGW', value: 16, disabled: false },
    { label: 'Glasgow', code: 'GLA', value: 6, disabled: false },
    { label: 'Heathrow', code: 'LHR', value: 7, disabled: false },
    { label: 'Humberside', code: 'HUY', value: 20, disabled: false },
    { label: 'Inverness', code: 'INV', value: 26, disabled: false },
    { label: 'Leeds Bradford', code: 'LBA', value: 14, disabled: false },
    { label: 'Liverpool', code: 'LPL', value: 8, disabled: false },
    { label: 'London City', code: 'LCY', value: 23, disabled: false },
    { label: 'Luton', code: 'LTN', value: 2, disabled: false },
    { label: 'Manchester', code: 'MAN', value: 1, disabled: false },
    { label: 'Newcastle', code: 'NCL', value: 15, disabled: false },
    { label: 'Norwich', code: 'NWI', value: 27, disabled: false },
    { label: 'Prestwick', code: 'PIK', value: 9, disabled: false },
    { label: 'Southampton', code: 'SOU', value: 31, disabled: false },
    { label: 'Southampton Port', code: 'GBSOU', value: 22, disabled: false },
    { label: 'Southend', code: 'SEN', value: 32, disabled: false },
    { label: 'Stansted', code: 'STN', value: 10, disabled: false },
    { label: 'Teesside International', code: 'MME', value: 28, disabled: false },
];

// Added for Migration Testing
if (config?.public?.STORYBLOK_VERSION === 'draft') {
    airportsList.push({ label: 'Test Airport', code: 'PTE', value: 99, disabled: false });
}

const airport: IAirportEntity = inject('airport', {} as IAirportEntity);
if (blok.SPSDefault) {
    searchStore.update({
        destination: {
            code: blok.SPSDefault,
            label: route.params.slug?.[1] || airport?.name || '',
            value: airportsList.find((airport) => airport.code === blok.SPSDefault)?.value ?? '',
            disabled: false,
        },
    });
}
</script>

<style lang="postcss" scoped>
.searchbox-container {
    @apply flex flex-col lg:flex-row bg-white mx-auto md:max-w-[670px] shadow-2xl;
}

.tabs {
    @apply flex md:flex-col bg-white md:bg-transparent;

    .tab {
        @apply relative flex-1 flex flex-col justify-center items-center bg-primary text-white lg:text-left text-center p-4 lg:max-w-[300px] lg:min-h-[140px] transition-all duration-300 ease-in-out;

        @media (min-width: 1024px) {
            &::after {
                content: '';
                @apply opacity-0 absolute top-[50%] left-[100%] -mt-[70px] border-transparent border-l-secondary;
                border-width: 70px 0 70px 36px;
                @apply transition-all duration-300 ease-in-out;
            }
        }

        .icons {
            @apply text-center mb-2 md:mb-0;
            span {
                @apply hidden md:inline-block;
            }
            .iconify {
                @apply mx-1;
            }
            img {
                @apply max-w-10 max-h-10 lg:pr-2;
            }
        }

        .title {
            @apply flex flex-col md:flex-row justify-center items-center text-sm md:text-base font-semibold md:leading-6 uppercase;
        }

        .subtitle {
            @apply hidden;
        }

        .icons {
            @apply md:mr-2 text-lg leading-6 whitespace-nowrap;
        }

        span {
            @apply z-[10];
        }

        &.active,
        &:hover {
            @apply flex bg-secondary;
            &::after {
                opacity: 1;
            }
        }
    }

    .tab.active .title,
    .tab.active .subtitle,
    .tab.active + .tab .title,
    .tab.active + .tab .subtitle,
    .tab:first-child .title,
    .tab:first-child .subtitle {
        @apply border-transparent;
    }
}

.forms {
    @apply transition-all w-full;
}

.form {
    @apply flex flex-col px-4 md:px-12 py-4 pb-4;

    &:deep(.input-group-container.guests) {
        @apply xl:max-w-[190px] xl:min-w-[190px];
    }

    :deep(.input-group-container) {
        @apply relative mb-2 md:mb-0 p-2 w-full bg-white rounded md:bg-transparent;

        .input-group-title {
            @apply static w-full text-sm md:whitespace-nowrap pointer-events-none whitespace-nowrap;

            &.has-icon {
                @apply left-9;
            }
        }

        &.error-container {
            @apply flex !py-0 md:!py-2 px-2 -mt-6 md:-mt-4;
        }

        .error-tooltip {
            @apply block w-full mt-[5px] px-2 py-2 bg-red-500 text-white text-xs rounded;
            &.custom-date {
                @apply inline;
                white-space: break-spaces;
                @media screen and (min-width: 767px) {
                    white-space: nowrap;
                }
            }
        }

        .input-group {
            @apply relative;

            .tooltip {
                @apply hidden;
            }

            .input-icon-prepend {
                @apply hidden;
            }

            .input-icon-append {
                @apply absolute top-[25px] right-[1px] max-w-[38px] max-h-[38px] p-2 flex items-center justify-center rounded-r-sm bg-secondary text-white pointer-events-none;
                &:not(.hide-desktop) + input {
                    @apply pr-7;
                }

                &[data-icon='sort-down'] {
                    @apply pt-2 pb-[14px] px-3.5;
                }
            }

            input,
            select {
                @apply h-10 w-full md:pt-4 md:pt-0 pl-2 pr-2 bg-white border border-gray-400 rounded text-sm text-gray-600 font-normal hover:border-primary outline-none text-ellipsis;

                &::placeholder {
                    @apply font-thin;
                }

                &[readonly] {
                    @apply cursor-pointer;
                }
            }

            select {
                @apply cursor-pointer hover:border-primary;
            }

            &.error {
                input,
                select {
                    @apply border-2 !border-red-500;
                }
            }

            .dropdown-list,
            .dropdown-select {
                @apply max-w-full min-w-full md:min-w-0 md:max-w-none absolute top-16 mt-2 md:mt-0 border border-gray-400 rounded bg-white shadow-md md:whitespace-nowrap z-[26];
            }

            .dropdown-list {
                &-item {
                    @apply flex py-4 px-4 border-b border-gray-300 cursor-pointer;
                    &.active {
                        @apply bg-gray-500;
                    }
                    &:hover {
                        @apply bg-gray-200;
                    }
                }
            }

            .dropdown-select {
                @apply py-2;
                &-item {
                    @apply flex py-2 px-4;
                    &-selectors {
                        @apply flex pl-4;
                        .button {
                            @apply mx-2 bg-none border-0 cursor-pointer text-primary-500 text-lg;
                            &:hover {
                                @apply text-primary-350;
                            }
                            &.disabled {
                                @apply text-gray-500;
                            }
                        }
                    }
                }
            }
        }
    }

    :deep(.submit) {
        @apply flex flex-col bg-transparent min-w-[150px];
        span {
            @apply inline-block text-sm;
            &.fullname {
                @apply invisible;
            }
            &.title {
                @apply invisible mb-1;
            }
        }
        button {
            @apply h-10 px-8 bg-primary hover:bg-secondary md:text-lg font-semibold text-white md:uppercase rounded transition-all duration-300;
            span {
                @apply inline-block text-lg;
                &.fullname {
                    @apply hidden;
                }
            }
            .loading-icon {
                @apply mt-2 w-[80px];
            }
        }
    }
}
</style>
