<template>
    <section v-if="blok" id="hero_footer" v-editable="blok">
        <div class="md:max-w-[1300px] md:mx-auto relative">
            <div v-show="blok.items && selectedTab" class="items">
                <template v-for="item in blok.items">
                    <template v-if="selectedTab === item?.Type || item?.Type === 'None'">
                        <div v-for="selectedType in item.items" :key="selectedType.id" class="flex items-center">
                            <div class="logo-row">
                                <template v-for="logo in selectedType.Logos" :key="logo.id">
                                    <div class="logo-item">
                                        <NuxtImg
                                            v-if="logo?.filename"
                                            :src="logo.filename"
                                            :title="logo.title"
                                            :alt="logo.alt"
                                            provider="storyblok"
                                            format="webp"
                                            loading="lazy"
                                            densities="x1"
                                        />
                                    </div>
                                </template>
                            </div>
                            <div class="content">
                                {{ selectedType.Content }}
                                <NuxtImg
                                    v-if="selectedType.FloatImage?.filename"
                                    :src="selectedType.FloatImage.filename"
                                    :title="selectedType.FloatImage.title"
                                    :alt="selectedType.FloatImage.alt"
                                    class="float-image"
                                    provider="storyblok"
                                    format="webp"
                                    loading="lazy"
                                    densities="x1"
                                />
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useSearchStore } from '../../../apps/_base/store/search';
const { blok } = defineProps<{ blok: any }>();

const searchStore: any = useSearchStore();

const selectedTab: any = useState('activeTab', getDefaultTab());

function getDefaultTab() {
    if (searchStore.activeTab) {
        return searchStore.activeTab;
    } else if (blok.items && blok.items[0]) {
        return blok.items[0]?.Type;
    } else {
        return null;
    }
}

watch(searchStore, (store) => {
    if (store.activeTab != selectedTab.value) {
        selectedTab.value = searchStore.activeTab;
    }
});
</script>

<style scoped lang="postcss">
#hero_footer {
    @apply hidden md:block bg-secondary text-white lg:px-8 overflow-hidden lg:overflow-visible;
}

.logo-row {
    @apply flex items-center gap-x-4 w-full;
}

.logo-item {
    @apply w-full flex items-center justify-between min-w-[170px] max-w-[170px] max-h-[62px] px-8 object-contain;

    &:nth-child(3) {
        @apply hidden xl:block;
    }
}

.content {
    @apply flex-1 relative flex items-center bg-primary text-white md:max-w-[50%] md:min-w-[390px] lg:max-w-[30%] min-h-[108px] pl-4 pr-8 md:pr-40 ml-12 lg:mr-20 text-xl font-semibold;

    &::before {
        @apply content-[''] absolute top-0 right-[100%] w-[0] h-[0] border-solid border-[transparent] border-r-primary border-r-[30px] border-y-[54px] border-l-0;
    }
}

.float-image {
    @apply hidden lg:flex items-end absolute -right-28 bottom-0 z-[1] max-w-[425px] max-h-[350px];
}
</style>
