import payload_plugin_rFgKo49LGr from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_ZcNQT3a6br from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pxGhtkw33Y from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2aJCLYX1As from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1rttDutGSL from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.6_jiti@2.4._pof3js7quv4o2t5mr4o22hoylm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_2OrXH8GUzI from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vFsn9EmY0b from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ze9KhGwxwi from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_a58JS61n5u from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_h1AAIfxZ5Z from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/.nuxt/components.plugin.mjs";
import prefetch_client_gzpnKq5OCf from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_3lry6ToLr3 from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.6_jiti@2.4.2_ters_x7a5hmpjwbeykpgwun4bwo5qmi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_plugin_eUEhfyDXEa from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_5clynZCQYE from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_mvoMcu6Std from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.30.1_typescript@5_gfmiedjpeubzdurcw46untz6pm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_RQWb0QQhsV from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_QEL6iP7MW1 from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_2hii42QBJd from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  payload_plugin_rFgKo49LGr,
  revive_payload_client_ZcNQT3a6br,
  unhead_pxGhtkw33Y,
  router_2aJCLYX1As,
  _0_siteConfig_1rttDutGSL,
  payload_client_2OrXH8GUzI,
  navigation_repaint_client_vFsn9EmY0b,
  check_outdated_build_client_ze9KhGwxwi,
  chunk_reload_client_a58JS61n5u,
  plugin_vue3_h1AAIfxZ5Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gzpnKq5OCf,
  plugin_OHU0vggoM9,
  plugin_3lry6ToLr3,
  nuxt_plugin_eUEhfyDXEa,
  plugin_5clynZCQYE,
  plugin_mvoMcu6Std,
  switch_locale_path_ssr_RQWb0QQhsV,
  i18n_QEL6iP7MW1,
  base64_2hii42QBJd
]