<template>
    <UiEmailSubscription id="SbEmailSubscription" :blok="props.blok" :apr="false" @submit="subscribeEmail" />
</template>

<script setup lang="ts">
import { useEmailSubscriptionStore } from '../../_base/store/emailSubscription';
import type { IEmailSubscriptionConfig } from '@nuxt-monorepo/ui/types';
const { t } = useI18n();
const props = defineProps<{ blok: IEmailSubscriptionConfig }>();
const emailSubscriptionStore = useEmailSubscriptionStore();
const backendError = ref('');
const config = useRuntimeConfig();
provide('backendError', backendError);

const subscribeEmail = async ({ email }) => {
    return await $fetch(`/api/subscribe`, {
        method: 'POST',
        body: { email, listId: config?.public.ITERABLE_LIST_ID },
        onResponse({ response }) {
            if (response.ok) {
                emailSubscriptionStore.updateIsSubscribed(true);
                backendError.value = '';
            }
        },
        onResponseError({ response }) {
            if (response) {
                backendError.value = response._data.message;
            }
        },
    }).catch((err) => {
        backendError.value = backendError.value || err;
    });
};
</script>

<style lang="postcss" scoped>
#SbEmailSubscription {
    :deep(.container) {
        .success,
        .error-tooltip {
            @apply hidden;
        }
        @apply flex-col py-3 max-w-[722px] px-0;
        .title-wrapper {
            h3,
            p {
                @apply text-center;
            }
            h3 {
                @apply mb-[10px] text-xl leading-[22px] md:text-3xl md:leading-[35px] font-bold text-primary uppercase;
            }
            p {
                @apply mb-[14px] text-[15px] md:text-base leading-5 font-normal text-primary text-center box-border m-0 md:px-[58px] py-0;
            }
        }
        .input-wrapper {
            width: 100%;
        }
        form {
            @apply relative flex;
            &:before {
                @apply content-[""] mt-[-5px] w-[15px] h-3 absolute mb-0 mx-0 left-[15px] top-2/4;
                background: url('~/assets/images/icon-mail.png') no-repeat;
            }
            button {
                @apply min-h-[55px] overflow-visible w-[165px] text-base leading-[18px] font-bold text-white cursor-pointer uppercase bg-[#f6ab2f] m-0 p-0 rounded-none border-[none];
            }
            input {
                @apply min-h-[55px] rounded-none pl-10 pr-0 py-0 text-body;
                &::placeholder {
                    @apply text-body;
                }
                &:focus {
                    @apply outline-0;
                }
            }
        }
        .validation-error {
            @apply max-w-max px-4 bg-white absolute;
        }
    }
    @apply bg-transparent;
}
</style>
