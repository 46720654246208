<template>
    <div>
        <transition :name="animationName">
            <div v-show="props.isOpen" class="main-wrapper z-[51] fixed inset-0">
                <div class="relative h-full">
                    <div class="overflow-y-auto">
                        <div class="flex-wrapper">
                            <TransitionGroup
                                class="modal-wrapper"
                                name="modal-content"
                                tag="modal-content"
                                enter="ease-out duration-300"
                                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enter-to="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leave-from="opacity-100 translate-y-0 sm:scale-100"
                                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div key="modal-content" ref="modalRef" class="margin-content">
                                    <div class="modal-content">
                                        <slot name="content"></slot>
                                    </div>
                                </div>
                            </TransitionGroup>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div v-show="props.isOpen" class="background"></div>
    </div>
</template>

<script setup lang="ts">
const modalRef = ref(null as any);

interface Props {
    isOpen: boolean;
    animationName?: string;
}

const emit = defineEmits<{
    (e: 'modalClosed'): void;
    (e: 'modalOpened'): void;
}>();

const props = defineProps<Props>();

const closeModal = () => {
    emit('modalClosed');
};

const escapeClick = (e: any) => {
    if (e.key === 'Escape') {
        closeModal();
    }
};

const clickOutsideModalContent = (e: any) => {
    if (modalRef.value && !modalRef.value.contains(e.target)) {
        closeModal();
    }
};

watch(
    () => props.isOpen,
    (newIsOpen) => {
        if (newIsOpen) {
            setTimeout(() => {
                document.addEventListener('click', clickOutsideModalContent);
                document.addEventListener('keydown', escapeClick);
            });
        } else {
            document.removeEventListener('click', clickOutsideModalContent);
            document.removeEventListener('keydown', escapeClick);
        }
    }
);
</script>
<style lang="postcss" scoped>
.modal-wrapper {
    @apply max-w-[1000px] w-full;
}

.main-wrapper {
    @apply z-[51] fixed inset-0;
}

.background {
    @apply fixed top-0 bottom-0 left-0 right-0 z-[50] inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.flex-wrapper {
    @apply flex min-h-full items-center justify-center text-center sm:items-center;
}

.margin-content {
    @apply relative transform overflow-hidden text-left shadow-xl transition-all sm:my-8 sm:w-full;
}
.modal-content {
    @apply bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4;
}
</style>
