export default {
    theme: {
        fontFamily: {
            sans: ['proximanova', 'sans-serif'],
        },
        screens: {
            xs: '400px',
            sm: '576px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px',
        },
        extend: {
            colors: {
                primary: {
                    DEFAULT: '#055988',
                },
                secondary: {
                    DEFAULT: '#f6ab2f',
                },
                tertiary: {
                    DEFAULT: '#34b4f5',
                    50: '#293f4d',
                },
                light: '#f8f9fa',
                body: { DEFAULT: '#666' },
                gray: {
                    DEFAULT: '#efefef',
                    50: '#f8f9fa',
                    800: '#343a40',
                },
                stars: {
                    900: '#EDBD40',
                    0: '#AEBFD1',
                },
                error: {
                    DEFAULT: '#FF3738',
                },
                btn: {
                    DEFAULT: '#e62571',
                    border: '#e93f82',
                    hover: '#cd185f',
                    borderHover: '#b41658',
                },
            },
        },
    },
};
