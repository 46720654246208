<template>
    <SearchboxMain id="sb_searchbox" :blok="blok" :loading="loading" @submit-storyblok="submitSearch" />
</template>

<script setup lang="ts">
defineProps({ blok: Object });
const config = useRuntimeConfig();
const loading = ref(false);

function addQueryParamsToUrl(url, params) {
    const urlObj = new URL(url);
    Object.keys(params).forEach((key) => urlObj.searchParams.append(key, params[key]));
    return urlObj.toString();
}

const submitSearch = async (body) => {
    loading.value = true;

    // Cancel loading after 5 seconds or if user navigates back
    setTimeout(() => {
        loading.value = false;
    }, 5000);

    const searchUrl = '/booking-quote/' + body.category + '-results';
    navigateTo(addQueryParamsToUrl(config?.public.BOOKING_URL + searchUrl, body), { external: true });
};
</script>

<style lang="postcss"></style>
