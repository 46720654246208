
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93Vdx5kazh2ZMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as _91IATA_93sgpTRh48kjMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93sdlRU8M2ugMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue?macro=true";
import { default as _91IATA_93Km8vRU15jnMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue?macro=true";
import { default as _91product_slug_93c8CI4oq9nrMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue?macro=true";
import { default as _91IATA_93KGtjUQOs70Meta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93Vt3dcOiO82Meta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue?macro=true";
import { default as health795ErDbYrcMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue?macro=true";
import { default as _91IATA_93jMXbXacfVbMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93VPybUKBnnuMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue?macro=true";
import { default as _91_46_46_46slug_931ixPXkR6xOMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue?macro=true";
import { default as component_45stubKWUwhZk1maMeta } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKWUwhZk1ma } from "/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_ua7naa5tuyyzvw7mmjht3sfw3u/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "airport-hotels-with-parking-IATA___en",
    path: "/airport-hotels-with-parking/:IATA()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue")
  },
  {
    name: "airport-hotels-with-parking-location-product_slug___en",
    path: "/airport-hotels-with-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-lounges-IATA___en",
    path: "/airport-lounges/:IATA()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue")
  },
  {
    name: "airport-lounges-location-product_slug___en",
    path: "/airport-lounges/:location()/:product_slug()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue")
  },
  {
    name: "airport-parking-IATA___en",
    path: "/airport-parking/:IATA()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue")
  },
  {
    name: "airport-parking-location-product_slug___en",
    path: "/airport-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue")
  },
  {
    name: "port-parking-IATA___en",
    path: "/port-parking/:IATA()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue")
  },
  {
    name: "port-parking-location-product_slug___en",
    path: "/port-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue")
  },
  {
    name: "subdomain-business-slug___en",
    path: "/subdomain/business/:slug(.*)*",
    component: () => import("/codebuild/output/src754310375/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue")
  },
  {
    name: component_45stubKWUwhZk1maMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubKWUwhZk1ma
  }
]