<template>
    <SbPage :blok="blok" class="pt-[80px]" />
    <!--modals-->
    <Component
        :is="globalStore.signInModal.component"
        v-if="globalStore && globalStore.signInModal"
        :global="globalStore.global.content"
        :blok="globalStore.signInModal"
    />
    <Component
        :is="globalStore.resetPasswordModal.component"
        v-if="globalStore && globalStore.resetPasswordModal"
        :global="globalStore.global.content"
        :blok="globalStore.resetPasswordModal"
    />
    <Component
        :is="globalStore.signUpModal.component"
        v-if="globalStore && globalStore.signUpModal"
        :global="globalStore.global.content"
        :blok="globalStore.signUpModal"
    />
    <Component
        :is="globalStore.subscribeModal.component"
        v-if="globalStore && globalStore.subscribeModal"
        :global="globalStore.global.content"
        :blok="globalStore.subscribeModal"
    />
</template>

<script setup>
import { useGlobalStore } from '../../_base/store/global';

defineProps({ blok: Object });
const globalStore = useGlobalStore();
</script>
<style lang="postcss" scoped>
:deep(.rich-text) p {
    @apply leading-6;
}
</style>
