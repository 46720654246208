<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<style lang="postcss">
@import 'assets/css/global.css';
@import '@/../_base/assets/css/animations.css';
@import '@/../_base/assets/css/richtext.css';
</style>
