<template>
    <div>
        <UiModalsSubscribeModal id="sbSubscribeModal" :blok="blok" :form-state="formState" @submit="submitForm" />
    </div>
</template>

<script lang="ts" setup>
import { useEmailSubscriptionStore } from '../../_base/store/emailSubscription';
const { blok } = defineProps<{ blok: any }>();

const formState: any = ref<any>({
    success: false,
    isSubmitted: false,
    responseMsg: null,
});

const emailSubscriptionStore = useEmailSubscriptionStore();
const config = useRuntimeConfig();
const submitForm = async (formData: any) => {
    const email = formData.email;
    formState.value.isSubmitted = true;
    return await $fetch(`/api/subscribe`, {
        method: 'POST',
        body: { email, listId: config?.public.ITERABLE_LIST_ID, signUpSource: 'headerModal' },
        onResponse({ response }) {
            if (response.ok) {
                emailSubscriptionStore.updateIsSubscribed(true);
                formState.value.responseMsg = '';
                formState.value.success = true;
                formState.value.isSubmitted = false;
            }
        },
        onResponseError({ response }) {
            if (response) {
                formState.value.responseMsg = response;
                formState.value.isSubmitted = false;
                console.error(response);
            }
        },
    });
};
</script>

<style lang="postcss" scoped></style>
