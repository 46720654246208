<template>
    <header>
        <div class="container mx-auto" :class="{ '!max-w-full': blok.FullWidth, 'is-logged-in': isLoggedIn }">
            <nuxt-link to="/" class="logo" external>
                <NuxtImg
                    v-if="global?.Logo?.filename"
                    :src="global.Logo.filename"
                    :title="global.Logo.title"
                    :alt="global.Logo.alt"
                    provider="storyblok"
                    format="webp"
                    loading="eager"
                    densities="x1"
                    :height="logoHeight"
                    :width="logoWidth"
                />
            </nuxt-link>
            <nav class="nav">
                <ul class="nav-row hidden md:flex">
                    <template v-if="blok.OldStyle">
                        <template v-for="(navItem, index) in blok.Navigation" :key="navItem._uid">
                            <li
                                v-if="navItem.Title || (navItem.Text && !navItem.ModalId)"
                                class="nav-row-item relative"
                                :data-cy="`deskTopDropDownDropdown-${index}`"
                                :class="[
                                    `dropdown-${index}`,
                                    {
                                        dropdown: navItem.Options,
                                        active: isActive(navItem),
                                        'current-page-nav-item': isCurrentPageNavItem(navItem),
                                    },
                                ]"
                                @click.stop="toggleMenu(navItem)"
                            >
                                <span v-if="navItem.Options">{{ navItem.Title || navItem.Text }}</span>
                                <nuxt-link v-else :to="getInternalLink(navItem?.Link)" external
                                    >{{ navItem.Title || navItem.Text }}
                                </nuxt-link>
                                <transition name="quick-fade">
                                    <ul v-show="isActive(navItem)" class="nav-dropdown" :class="navItem.Direction">
                                        <template v-for="dropdownItem in navItem.Options" :key="dropdownItem._uid">
                                            <li
                                                class="nav-dropdown-item"
                                                :class="{
                                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                }"
                                            >
                                                <nuxt-link :to="getInternalLink(dropdownItem?.Link)" external>
                                                    {{ dropdownItem.Text }}
                                                </nuxt-link>
                                            </li>
                                        </template>
                                    </ul>
                                </transition>
                            </li>
                            <li
                                v-if="navItem.ModalId"
                                class="nav-row-item"
                                :data-cy="`deskTopDropDownDropdown-${index}`"
                                :class="[
                                    `dropdown-${index}`,
                                    {
                                        dropdown: navItem.Options,
                                        active: isActive(navItem),
                                        'current-page-nav-item': isCurrentPageNavItem(navItem),
                                    },
                                ]"
                                @click.stop="toggleMenu(navItem)"
                            >
                                <span v-if="navItem.Options">{{ navItem.Title || navItem.Text }}</span>
                                <nuxt-link v-else :to="getInternalLink(navItem?.Link)" external>
                                    {{ navItem.Title || navItem.Text }}
                                </nuxt-link>
                            </li>
                        </template>
                    </template>
                    <template v-for="(navItem, index) in blok.Navigation" v-else :key="navItem._uid">
                        <li
                            v-if="(navItem.ModalId && !isLoggedIn) || (hasLink(navItem) && !navItem.Options)"
                            class="nav-row-item relative"
                            :data-cy="`deskTopDropDownDropdown-${index}`"
                            :class="[
                                `dropdown-${index}`,
                                {
                                    dropdown: navItem.Options,
                                    active: isActive(navItem) && !navItem.Link?.url,
                                    'is-single-button': navItem.Link?.url || navItem.ModalId,
                                    'modal-button': navItem.ModalId,
                                    'rich-text-menu-item': navItem.Text?.type === 'doc',
                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                },
                            ]"
                            @click.stop="toggleMenu(navItem)"
                        >
                            <nuxt-link
                                v-if="navItem.Text?.type === 'doc'"
                                :to="hasLink(navItem) && !navItem.ModalId ? getInternalLink(navItem?.Link) : '#'"
                                external
                                :style="getNavItemStyles(navItem)"
                                ><SimpleRichtext :blok="{ text: navItem.Text }" />
                            </nuxt-link>
                            <span
                                v-else-if="(!hasLink(navItem) && navItem?.Options) || navItem?.ModalId"
                                tabindex="0"
                                @keydown.enter="onNavItemPressEnter(navItem)"
                            >
                                {{ navItem?.Title || navItem?.Text }}
                            </span>
                            <nuxt-link v-else :to="getInternalLink(navItem.Link)" external :target="navItem.Target"
                                >{{ navItem.Title || navItem.Text }}
                            </nuxt-link>
                            <transition name="quick-fade">
                                <ul
                                    v-show="
                                        isActive(navItem) &&
                                        (!navItem.optionContentType || navItem.optionContentType === 'links')
                                    "
                                    class="nav-dropdown"
                                    :class="navItem.Direction"
                                >
                                    <template v-for="dropdownItem in navItem.Options" :key="dropdownItem._uid">
                                        <li
                                            class="nav-dropdown-item"
                                            :class="{
                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                            }"
                                        >
                                            <nuxt-link
                                                :to="getInternalLink(dropdownItem.Link)"
                                                external
                                                :target="dropdownItem.Target"
                                                >{{ dropdownItem.Text }}
                                            </nuxt-link>
                                        </li>
                                    </template>
                                </ul>
                            </transition>
                        </li>

                        <li
                            v-if="
                                !navItem.ShowOnLogin &&
                                !!!navItem.ModalId &&
                                (!hasLink(navItem) || (hasLink(navItem) && navItem.Options))
                            "
                            class="nav-row-item relative"
                            :data-cy="`deskTopDropDownDropdown-${index}`"
                            :class="[
                                `dropdown-${index}`,
                                {
                                    dropdown: navItem.Options,
                                    active: isActive(navItem),
                                    'rich-text-menu-item': navItem.Text?.type === 'doc',
                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                },
                            ]"
                            :aria-expanded="isActive(navItem) ? 'true' : 'false'"
                            role="menuitem"
                            tabindex="0"
                            @click.stop
                            @mouseenter.stop="toggleMenu(navItem, false, false, true)"
                            @mouseleave.stop="toggleMenu(navItem, false, false, true)"
                            @keyup.enter.stop="toggleMenu(navItem, true)"
                        >
                            <nuxt-link
                                v-if="navItem.Text?.type === 'doc'"
                                :to="getInternalLink(navItem.Link)"
                                external
                                :style="getNavItemStyles(navItem)"
                                ><SimpleRichtext :blok="{ text: navItem.Text }" />
                            </nuxt-link>
                            <span v-else-if="navItem.Options && !hasLink(navItem)">{{
                                navItem.Title || navItem.Text
                            }}</span>
                            <nuxt-link v-else :to="getInternalLink(navItem.Link)" external :target="navItem.Target"
                                >{{ navItem.Title || navItem.Text }}
                            </nuxt-link>
                            <NuxtIcon v-if="navItem.Text?.type !== 'doc'" name="ri:arrow-down-s-line" />
                            <transition name="quick-fade">
                                <ul
                                    v-show="
                                        isActive(navItem) &&
                                        (!navItem.optionContentType || navItem.optionContentType === 'links')
                                    "
                                    class="nav-dropdown"
                                    :class="[
                                        navItem.Direction,
                                        {
                                            'current-page-nav-item': isCurrentPageNavItem(navItem),
                                        },
                                    ]"
                                >
                                    <template v-for="dropdownItem in navItem.Options" :key="dropdownItem._uid">
                                        <li
                                            v-if="!dropdownItem.Options?.length"
                                            class="nav-dropdown-item"
                                            :class="{
                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                            }"
                                        >
                                            <a
                                                v-if="dropdownItem.ModalId"
                                                tabindex="0"
                                                href=""
                                                @click.prevent="toggleMenu(dropdownItem)"
                                            >
                                                {{ dropdownItem.Text }}
                                            </a>
                                            <nuxt-link
                                                v-else
                                                tabindex="0"
                                                :to="getInternalLink(dropdownItem.Link)"
                                                :target="dropdownItem.Target"
                                                external
                                            >
                                                {{ dropdownItem.Text }}
                                            </nuxt-link>
                                        </li>
                                        <li
                                            v-else
                                            class="nav-row-item"
                                            :data-cy="`desktopInnerDropdown-${index}`"
                                            :class="{
                                                dropdown: dropdownItem.Options,
                                                active: isActive(dropdownItem),
                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                            }"
                                            tabindex="0"
                                            @click.stop
                                            @keyup.enter.stop="toggleMenu(dropdownItem, true, true)"
                                            @mouseenter.stop="toggleMenu(dropdownItem)"
                                            @mouseleave.stop="toggleMenu(dropdownItem)"
                                        >
                                            <a data-dropdown="true">
                                                {{ dropdownItem.Title }}
                                                <NuxtIcon name="ri:arrow-right-s-line" />
                                            </a>

                                            <transition name="quick-fade">
                                                <ul
                                                    v-show="isActive(dropdownItem)"
                                                    class="nav-dropdown inner"
                                                    data-dropdown="true"
                                                    :style="`${Number(dropdownItem.ColumnsCount) > 1 ? `display: grid; grid-template-rows: repeat(${rowsCount(dropdownItem)}, max-content);` : ``}`"
                                                >
                                                    <template
                                                        v-for="(
                                                            dropdownItemInner, dropdownItemInnerIndex
                                                        ) in dropdownItem.Options"
                                                        :key="dropdownItemInner._uid"
                                                    >
                                                        <li
                                                            v-if="!!dropdownItemInner.ModalId"
                                                            class="modal-button"
                                                            :class="{
                                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                            }"
                                                            @click="toggleMenu(dropdownItemInner)"
                                                        >
                                                            <a>{{ dropdownItemInner.Text }}</a>
                                                        </li>
                                                        <li
                                                            v-else
                                                            :class="{
                                                                'top-link': topNavClass(
                                                                    dropdownItemInnerIndex,
                                                                    dropdownItem
                                                                ),
                                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                            }"
                                                        >
                                                            <nuxt-link
                                                                tabindex="0"
                                                                :to="getInternalLink(dropdownItemInner.Link)"
                                                                :target="dropdownItemInner.Target"
                                                                external
                                                            >
                                                                {{ dropdownItemInner.Text }}
                                                            </nuxt-link>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </transition>
                                        </li>
                                    </template>
                                </ul>
                            </transition>
                        </li>

                        <li
                            v-if="navItem.ShowOnLogin && isLoggedIn"
                            class="nav-row-item relative"
                            :data-cy="`deskTopDropDownDropdown-${index}`"
                            :class="[
                                `dropdown-${index}`,
                                {
                                    'my-account': navItem.Title === 'MY ACCOUNT',
                                    dropdown: navItem.Options,
                                    active: isActive(navItem),
                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                },
                            ]"
                            :aria-expanded="isActive(navItem) ? 'true' : 'false'"
                            role="menuitem"
                            tabindex="0"
                            @click.stop
                            @mouseenter.stop="toggleMenu(navItem, false, false, true)"
                            @mouseleave.stop="toggleMenu(navItem, false, false, true)"
                            @keyup.enter.stop="toggleMenu(navItem, true)"
                        >
                            <span v-if="navItem.Options">{{ navItem.Title || navItem.Text }}</span>
                            <nuxt-link v-else :to="getInternalLink(navItem.Link)" external :target="navItem.Target">
                                {{ navItem.Title || navItem.Text }}
                            </nuxt-link>
                            <NuxtIcon name="ri:arrow-down-s-line" />
                            <transition name="quick-fade">
                                <ul
                                    v-show="
                                        isActive(navItem) &&
                                        (!navItem.optionContentType || navItem.optionContentType === 'links')
                                    "
                                    class="nav-dropdown"
                                    :class="navItem.Direction"
                                >
                                    <template v-for="dropdownItem in navItem.Options" :key="dropdownItem._uid">
                                        <li
                                            v-if="!dropdownItem.Options?.length"
                                            class="nav-dropdown-item"
                                            :class="{
                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                            }"
                                        >
                                            <a
                                                v-if="getInternalLink(dropdownItem.Link) === '/logout'"
                                                href="#"
                                                @click.prevent="logout()"
                                            >
                                                {{ dropdownItem.Text }}
                                            </a>
                                            <nuxt-link
                                                v-else
                                                tabindex="0"
                                                :to="getInternalLink(dropdownItem.Link)"
                                                :target="dropdownItem.Target"
                                                external
                                            >
                                                {{ dropdownItem.Text }}
                                            </nuxt-link>
                                        </li>
                                        <li
                                            v-else
                                            class="nav-row-item"
                                            :data-cy="`desktopInnerDropdown-${index}`"
                                            :class="{
                                                dropdown: dropdownItem.Options,
                                                active: isActive(dropdownItem),
                                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                                            }"
                                            tabindex="0"
                                            @click.stop
                                            @keyup.enter.stop="toggleMenu(dropdownItem, true, true)"
                                            @mouseenter.stop="toggleMenu(dropdownItem)"
                                            @mouseleave.stop="toggleMenu(dropdownItem)"
                                        >
                                            <a data-dropdown="true">
                                                {{ dropdownItem.Title }}
                                                <NuxtIcon name="ri:arrow-right-s-line" />
                                            </a>
                                            <transition name="quick-fade">
                                                <ul
                                                    v-if="isActive(dropdownItem)"
                                                    class="nav-dropdown inner"
                                                    data-dropdown="true"
                                                >
                                                    <template
                                                        v-for="dropdownItemInner in dropdownItem.Options"
                                                        :key="dropdownItemInner._uid"
                                                    >
                                                        <li>
                                                            <nuxt-link
                                                                tabindex="0"
                                                                :to="getInternalLink(dropdownItemInner.Link)"
                                                                :target="dropdownItemInner.Target"
                                                                external
                                                            >
                                                                {{ dropdownItemInner.Text }}
                                                            </nuxt-link>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </transition>
                                        </li>
                                    </template>
                                </ul>
                            </transition>
                        </li>
                    </template>
                </ul>

                <ul
                    v-if="blok.OldStyle && (!blok.mobileMenuStyle || blok.mobileMenuStyle === 'slide-right')"
                    class="mobile-menu md:hidden"
                    :class="{ active: mobileMenuIsActive }"
                >
                    <div class="close-button" data-cy="closeMobileMenu" @click.stop="toggleMobileMenu">
                        <span>×</span>
                    </div>
                    <template v-for="navItem in blok.MobileNavigation" :key="navItem._uid">
                        <li
                            v-if="navItem.component !== 'MenuIcon' && navItem.component !== 'Separator'"
                            :class="{
                                'drop-down-button': navItem.Options,
                                'current-page-nav-item': isCurrentPageNavItem(navItem),
                            }"
                        >
                            <nuxt-link
                                v-if="!navItem.Options && !navItem.ModalId"
                                :to="getInternalLink(navItem.Link) || '#'"
                                :target="navItem.Target"
                                external
                            >
                                <div>
                                    <NuxtIcon name="ri:arrow-right-s-line" />
                                </div>
                                {{ navItem.Text }}
                            </nuxt-link>
                            <a
                                v-else
                                href="#"
                                :class="{ active: isActive(navItem) }"
                                @click.prevent.stop="toggleMenu(navItem)"
                            >
                                <div>
                                    <NuxtIcon name="ri:arrow-right-s-line" />
                                </div>
                                {{ navItem.Title || navItem.Text }}
                                <NuxtIcon name="ri:arrow-right-s-line" />
                            </a>
                            <ul
                                v-if="navItem.Options"
                                class="nav-mobile-dropdown"
                                :class="{ active: isActive(navItem) }"
                            >
                                <template v-for="dropdownItem in navItem.Options" :key="dropdownItem._uid">
                                    <li
                                        class="nav-mobile-dropdown-item"
                                        :class="{
                                            'current-page-nav-item': isCurrentPageNavItem(navItem),
                                        }"
                                    >
                                        <nuxt-link
                                            :to="getInternalLink(dropdownItem.Link)"
                                            external
                                            :target="dropdownItem.Target"
                                            >{{ dropdownItem.Text }}
                                        </nuxt-link>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>
                <div
                    v-else-if="
                        !blok.OldStyle &&
                        (!blok.mobileMenuStyle ||
                            blok.mobileMenuStyle === 'slide-right' ||
                            blok.mobileMenuStyle === 'slide-left')
                    "
                    class="mobile-menu md:hidden new-header"
                    :class="{ active: mobileMenuIsActive, 'slide-left': blok.mobileMenuStyle === 'slide-left' }"
                    @click.stop
                    @touchstart.passive="startDrag"
                    @touchend.passive="endDrag"
                >
                    <div class="close-button" data-cy="closeMobileMenu" @click.stop="toggleMobileMenu">
                        <span>×</span>
                    </div>
                    <div class="scroll-wrapper">
                        <div class="scroll" :class="selectedLevel">
                            <ul>
                                <template v-for="(navItem, index) in blok.MobileNavigation" :key="navItem._uid">
                                    <li
                                        v-if="navItem.component !== 'MenuIcon' && navItem.component !== 'Separator'"
                                        :class="{
                                            'drop-down-button': navItem.Options,
                                            'current-page-nav-item': isCurrentPageNavItem(navItem),
                                        }"
                                    >
                                        <a
                                            v-if="navItem.ShowOnLogin && isLoggedIn && navItem.component === 'Dropdown'"
                                            href="#"
                                            :data-cy="`mobileMenuDropdown-${index}`"
                                            :class="{ active: isActive(navItem) }"
                                            @click.prevent.stop="
                                                getInternalLink(navItem.Link) === '/logout'
                                                    ? logout()
                                                    : changeMobileMenuLevel('level-2', null, navItem)
                                            "
                                        >
                                            <template v-if="navItem.Icon?.icon && !navItem.CustomIcon?.filename">
                                                <NuxtIcon v-if="navItem.Icon.icon" :name="`ri:${navItem.Icon.icon}`" />
                                            </template>
                                            <div v-if="navItem.CustomIcon?.filename">
                                                <NuxtImg
                                                    v-if="navItem.CustomIcon.filename"
                                                    :src="navItem.CustomIcon.filename"
                                                    :title="navItem.CustomIcon.filename"
                                                    :alt="navItem.CustomIcon.filename"
                                                    width="16"
                                                    height="16"
                                                    provider="storyblok"
                                                    format="webp"
                                                    loading="lazy"
                                                />
                                            </div>
                                            {{ navItem.Title || navItem.Text }}
                                            <NuxtIcon v-if="navItem.Options" name="ri:arrow-right-s-line" />
                                        </a>
                                        <nuxt-link
                                            v-else-if="navItem.Text?.type === 'doc' && navItem?.Link.url"
                                            :to="getInternalLink(navItem?.Link)"
                                            external
                                            :style="`
                                        ${navItem.background?.color ? `background-color: ${navItem.background?.color};` : ''}
                                        ${navItem.color?.color ? `color: ${navItem.color?.color};` : ''}
                                    `"
                                            @click.prevent.stop="toggleMenu(navItem)"
                                            ><SimpleRichtext :blok="{ text: navItem.Text }" />
                                        </nuxt-link>
                                        <nuxt-link
                                            v-else-if="navItem.Text?.type === 'doc' && navItem.ModalId"
                                            href="#"
                                            :style="`
                                        ${navItem.background?.color ? `background-color: ${navItem.background?.color};` : ''}
                                        ${navItem.color?.color ? `color: ${navItem.color?.color};` : ''}
                                    `"
                                            @click.prevent.stop="toggleMenu(navItem)"
                                        >
                                            <SimpleRichtext :blok="{ text: navItem.Text }" />
                                        </nuxt-link>
                                        <a
                                            v-else-if="
                                                (navItem?.ModalId && !navItem.ShowOnLogin && !isLoggedIn) ||
                                                (!navItem.ModalId && !navItem.ShowOnLogin && !navItem?.Link.url)
                                            "
                                            href="#"
                                            :data-cy="`mobileMenuDropdown-${index}`"
                                            :class="{ active: isActive(navItem) }"
                                            @click.prevent.stop="changeMobileMenuLevel('level-2', null, navItem)"
                                        >
                                            <template v-if="navItem.Icon?.icon && !navItem.CustomIcon?.filename">
                                                <NuxtIcon v-if="navItem.Icon.icon" :name="`ri:${navItem.Icon.icon}`" />
                                            </template>
                                            <div v-if="navItem.CustomIcon?.filename">
                                                <NuxtImg
                                                    v-if="navItem.CustomIcon?.filename"
                                                    :src="navItem.CustomIcon.filename"
                                                    :alt="navItem.CustomIcon.filename"
                                                    format="webp"
                                                    loading="eager"
                                                    height="16"
                                                    width="16"
                                                />
                                            </div>
                                            {{ navItem.Title || navItem.Text }}
                                            <NuxtIcon v-if="navItem.Options" name="ri:arrow-right-s-line" />
                                        </a>
                                        <nuxt-link
                                            v-else-if="navItem.Link && !navItem.ModalId && !navItem.ShowOnLogin"
                                            :to="getInternalLink(navItem.Link)"
                                            :target="navItem.Target"
                                            external
                                        >
                                            <template v-if="navItem.Icon?.icon && !navItem.CustomIcon?.filename">
                                                <NuxtIcon v-if="navItem.Icon.icon" :name="`ri:${navItem.Icon.icon}`" />
                                            </template>
                                            {{ navItem.Text }}
                                        </nuxt-link>
                                        <nuxt-link
                                            v-else-if="
                                                navItem.Link &&
                                                !navItem.ModalId &&
                                                navItem.ShowOnLogin &&
                                                isLoggedIn &&
                                                navItem.component !== 'Dropdown'
                                            "
                                            :target="navItem.Target"
                                            external
                                            @click.prevent.stop="
                                                getInternalLink(navItem.Link) === '/logout'
                                                    ? logout()
                                                    : navigateTo(getInternalLink(navItem.Link), {
                                                          external: true,
                                                      })
                                            "
                                        >
                                            <template v-if="navItem.Icon?.icon && !navItem.CustomIcon?.filename">
                                                <NuxtIcon v-if="navItem.Icon.icon" :name="`ri:${navItem.Icon.icon}`" />
                                            </template>
                                            {{ navItem.Text }}
                                        </nuxt-link>
                                    </li>
                                    <hr
                                        v-if="navItem.component === 'Separator'"
                                        :style="`border-bottom: 1px solid ${navItem.Color.color}`"
                                    />
                                </template>
                            </ul>
                            <div class="level-wrapper">
                                <p class="back-button" @click.stop="changeMobileMenuLevel('level-1')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                                        <path
                                            d="M16.67 9.17v1.66h-10l4.58 4.59-1.18 1.18-6.6-6.6 6.6-6.6 1.18 1.18-4.58 4.59h10Z"
                                            fill="#293F4D"
                                        />
                                    </svg>
                                    Back
                                </p>
                                <hr class="top" />
                                <p class="title">{{ selectedNavItem?.Title }}</p>
                                <hr class="bottom" />
                                <ul class="nav-mobile-dropdown">
                                    <template v-if="selectedNavItem">
                                        <template
                                            v-for="(dropdownItem, dIndex) in selectedNavItem.Options"
                                            :key="dropdownItem._uid"
                                        >
                                            <li
                                                v-if="dropdownItem.Options"
                                                class="nav-mobile-dropdown-item"
                                                :class="{
                                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                }"
                                                :data-cy="`mobileMenuDropdownLevel2-${dIndex}`"
                                                @click.stop="changeMobileMenuLevel('level-3', dropdownItem)"
                                            >
                                                <span>
                                                    {{ dropdownItem.Title }}
                                                    <NuxtIcon name="ri:arrow-right-s-line" />
                                                </span>
                                            </li>
                                            <li
                                                v-else
                                                class="nav-mobile-dropdown-item"
                                                :class="{
                                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                }"
                                            >
                                                <nuxt-link
                                                    :to="getInternalLink(dropdownItem.Link)"
                                                    external
                                                    @click="(event) => handleClickMobMenuLink(event, dropdownItem)"
                                                >
                                                    {{ dropdownItem.Text }}
                                                </nuxt-link>
                                            </li>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                            <div class="level-wrapper">
                                <p class="back-button" @click.stop="changeMobileMenuLevel('level-2')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                                        <path
                                            d="M16.67 9.17v1.66h-10l4.58 4.59-1.18 1.18-6.6-6.6 6.6-6.6 1.18 1.18-4.58 4.59h10Z"
                                            fill="#293F4D"
                                        />
                                    </svg>
                                    Back
                                </p>
                                <hr class="top" />
                                <p class="title">{{ selectedLevel3NavItem?.Title }}</p>
                                <hr class="bottom" />
                                <ul class="nav-mobile-dropdown">
                                    <template v-if="selectedLevel3NavItem">
                                        <template
                                            v-for="dropdownItem in selectedLevel3NavItem.Options"
                                            :key="dropdownItem._uid"
                                        >
                                            <li
                                                class="nav-mobile-dropdown-item"
                                                :class="{
                                                    'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                }"
                                            >
                                                <nuxt-link
                                                    :to="getInternalLink(dropdownItem.Link)"
                                                    external
                                                    @click="(event) => handleClickMobMenuLink(event, dropdownItem)"
                                                >
                                                    {{ dropdownItem.Text }}
                                                </nuxt-link>
                                            </li>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="navigation-button md:hidden flex" data-cy="openMobileMenu" @click.stop="toggleMobileMenu">
                <NuxtIcon v-if="!mobileMenuIsActive" name="ri:menu-fill" />
                <NuxtIcon v-else name="ri:close-fill" />
            </div>
        </div>

        <transition name="dropdown">
            <div
                v-if="blok.mobileMenuStyle === 'dropdown'"
                class="new-header mobile-fall-menu"
                :class="{ active: mobileMenuIsActive }"
                @click.stop
            >
                <div class="scroll-wrapper">
                    <div class="scroll" :class="selectedLevel">
                        <ul>
                            <template v-for="(navItem, index) in blok.MobileNavigation" :key="navItem._uid">
                                <li
                                    v-if="navItem.component !== 'MenuIcon' && navItem.component !== 'Separator'"
                                    :class="{
                                        'drop-down-button': navItem.Options,
                                        'rich-text-menu-item': navItem.Text?.type === 'doc',
                                        'current-page-nav-item': isCurrentPageNavItem(navItem),
                                    }"
                                >
                                    <a
                                        v-if="navItem.ShowOnLogin && isLoggedIn"
                                        href="#"
                                        :data-cy="`mobileMenuDropdown-${index}`"
                                        :class="{ active: isActive(navItem) }"
                                        @click.prevent.stop="
                                            getInternalLink(navItem?.Link) === '/logout'
                                                ? logout()
                                                : mobileDropDownMenuToggle(navItem)
                                        "
                                    >
                                        <template v-if="navItem.Icon?.icon && !navItem.CustomIcon?.filename">
                                            <NuxtIcon v-if="navItem.Icon.icon" :name="`ri:${navItem.Icon.icon}`" />
                                        </template>
                                        <div v-if="navItem.CustomIcon?.filename">
                                            <img :src="navItem.CustomIcon.filename" alt="navItem.CustomIcon.filename" />
                                        </div>
                                        {{ navItem.Title || navItem.Text }}
                                        <NuxtIcon v-if="navItem.Options" name="ri:arrow-right-s-line" />
                                    </a>
                                    <nuxt-link
                                        v-else-if="navItem.Text?.type === 'doc' && navItem?.Link.url"
                                        :to="getInternalLink(navItem?.Link)"
                                        external
                                        :style="`
                                        ${navItem.background?.color ? `background-color: ${navItem.background?.color};` : ''}
                                        ${navItem.color?.color ? `color: ${navItem.color?.color};` : ''}
                                    `"
                                        @click.prevent.stop="toggleMenu(navItem)"
                                        ><SimpleRichtext :blok="{ text: navItem.Text }" />
                                    </nuxt-link>
                                    <a
                                        v-else-if="navItem.Text?.type === 'doc' && navItem.ModalId"
                                        href="#"
                                        :style="`
                                        ${navItem.background?.color ? `background-color: ${navItem.background?.color};` : ''}
                                        ${navItem.color?.color ? `color: ${navItem.color?.color};` : ''}
                                    `"
                                        @click.prevent.stop="toggleMenu(navItem)"
                                    >
                                        <SimpleRichtext :blok="{ text: navItem.Text }" />
                                    </a>
                                    <a
                                        v-else-if="
                                            (navItem?.ModalId && !navItem.ShowOnLogin && !isLoggedIn) ||
                                            (!navItem.ModalId && !navItem.ShowOnLogin && !navItem?.Link.url)
                                        "
                                        href="#"
                                        :data-cy="`mobileMenuDropdown-${index}`"
                                        :class="{ active: isActive(navItem) }"
                                        @click.prevent.stop="mobileDropDownMenuToggle(navItem)"
                                    >
                                        <template v-if="navItem.Icon?.icon && !navItem.CustomIcon?.filename">
                                            <NuxtIcon v-if="navItem.Icon.icon" :name="`ri:${navItem.Icon.icon}`" />
                                        </template>
                                        <div v-if="navItem.CustomIcon?.filename">
                                            <img :src="navItem.CustomIcon.filename" alt="navItem.CustomIcon.filename" />
                                        </div>
                                        {{ navItem.Title || navItem.Text }}
                                        <NuxtIcon
                                            v-if="navItem.Options && activeMobileMenuIndex !== navItem._uid"
                                            name="ri:arrow-right-s-line"
                                        />
                                        <NuxtIcon
                                            v-else-if="
                                                navItem.Options &&
                                                activeMobileMenuIndex &&
                                                activeMobileMenuIndex === navItem._uid
                                            "
                                            name="ri:arrow-down-s-line"
                                        />
                                    </a>
                                    <nuxt-link
                                        v-if="(navItem?.Link?.url || navItem?.Link?.cached_url) && !navItem.ModalId"
                                        :to="getInternalLink(navItem?.Link)"
                                        :target="navItem.Target"
                                        external
                                        >{{ navItem.Text }}
                                    </nuxt-link>

                                    <div class="level-wrapper">
                                        <ul class="nav-mobile-dropdown">
                                            <template v-if="selectedNavItem && activeMobileMenuIndex === navItem._uid">
                                                <template
                                                    v-for="(dropdownItem, dIndex) in selectedNavItem.Options"
                                                    :key="dropdownItem._uid"
                                                >
                                                    <li
                                                        v-if="dropdownItem.Options"
                                                        class="nav-mobile-dropdown-item"
                                                        :class="{
                                                            'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                        }"
                                                        :data-cy="`mobileMenuDropdownLevel2-${dIndex}`"
                                                        @click.stop="changeMobileMenuLevel('level-3', dropdownItem)"
                                                    >
                                                        <span>
                                                            {{ dropdownItem.Title }}
                                                            <NuxtIcon name="ri:arrow-right-s-line" />
                                                        </span>
                                                    </li>
                                                    <li
                                                        v-else
                                                        class="nav-mobile-dropdown-item"
                                                        :class="{
                                                            'current-page-nav-item': isCurrentPageNavItem(navItem),
                                                        }"
                                                    >
                                                        <nuxt-link
                                                            :to="getInternalLink(dropdownItem?.Link)"
                                                            external
                                                            @click="
                                                                (event) => handleClickMobMenuLink(event, dropdownItem)
                                                            "
                                                        >
                                                            {{ dropdownItem.Text }}
                                                        </nuxt-link>
                                                    </li>
                                                </template>
                                            </template>
                                        </ul>
                                    </div>
                                </li>
                                <hr
                                    v-if="navItem.component === 'Separator'"
                                    :style="`border-bottom: 1px solid ${navItem.Color.color}`"
                                />
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="custom-fade">
            <div v-if="mobileMenuIsActive" class="mobile-nav-bg hidden" @click="toggleMobileMenu"></div>
        </transition>
    </header>
</template>

<script setup>
import { getInternalLink } from '../../../../apps/_base/utils/internalLinking';
import { useModalStore } from '../../../../apps/_base/store/modal';
import { useAuthStore } from '../../../../apps/_base/store/auth';
import { Icon } from 'vue3-carousel';

const modalStore = useModalStore();
const authStore = useAuthStore();
const { blok, global, logoHeight, logoWidth } = defineProps({
    blok: Object,
    global: Object,
    logoHeight: Number,
    logoWidth: Number,
});
const activeItems = ref({});
const mobileMenuIsActive = ref(false);
const cachedId = ref(null);
const selectedLevel = ref('level-1');
const activeMobileMenuIndex = ref(null);
const selectedNavItem = ref(null);
const selectedLevel3NavItem = ref(null);

// swipe event initial values
const startX = ref(0);
const startTime = ref(0);
const minSwipeDistance = ref(50);
const minSwipeSpeed = ref(0.5);
const route = useRoute();

const isLoggedIn = computed(() => {
    return !!(authStore.$state.id && authStore.$state.role && authStore.$state.csrf);
});

const startDrag = (e) => {
    startX.value = e.changedTouches[0].clientX;
    startTime.value = Date.now();
};
const endDrag = (e) => {
    const endX = e.changedTouches[0].clientX;
    const endTime = Date.now();
    const deltaX = endX - startX.value;
    const deltaTime = endTime - startTime.value;
    const speed = Math.abs(deltaX) / deltaTime;

    if (deltaX > 0 && Math.abs(deltaX) > minSwipeDistance.value && speed > minSwipeSpeed.value) {
        // Swipe right detected with sufficient distance and speed and close mobile menu
        toggleMobileMenu();
    }
};
const toggleMenu = (navItem, accessibility = false, innerDropDown = false, main = false) => {
    if (navItem.ModalId) {
        modalStore.toggleModal(navItem.ModalId, true);
        closeAllDropDowns();
        toggleMobileMenu();
    } else {
        // if we hover on the inner dropdown in dropdown
        if (innerDropDown) {
            // if we have cached value, we toggle previous value to false for close previous dropdown
            if (cachedId.value) {
                activeItems.value[cachedId.value] = false;
            }
            // open current inner dropdown
            activeItems.value[navItem._uid] = true;
            // write cached value for current inner dropdown
            cachedId.value = navItem._uid;
            return;
        }

        // on-leave we close dropdown
        if (activeItems.value[navItem._uid]) {
            activeItems.value[navItem._uid] = false;
            return;
        }

        // on click on another dropdown we close previous
        if (blok.OldStyle) {
            Object.keys(activeItems.value).forEach((key) => {
                activeItems.value[key] = key === navItem._uid;
            });
        }
        // close all dropdowns
        if ((accessibility && !innerDropDown) || main) {
            closeAllDropDowns();
        }
        // set active items field activeItems.value = {some uid of nav item: true}
        activeItems.value[navItem._uid] = true;
    }
};

const onNavItemPressEnter = (navItem) => {
    toggleMenu(navItem);
};
// change menu mobile level for scrolling
const changeMobileMenuLevel = (level, navItemLevel3 = selectedLevel3NavItem.value, navItem = selectedNavItem.value) => {
    if (navItem.ModalId) {
        modalStore.toggleModal(navItem.ModalId, true);
        toggleMobileMenu();
        return;
    }

    selectedLevel.value = level;
    selectedNavItem.value = navItem;
    selectedLevel3NavItem.value = navItemLevel3;
};

const mobileDropDownMenuToggle = (navItem = selectedNavItem.value) => {
    if (activeMobileMenuIndex.value === navItem._uid) {
        activeMobileMenuIndex.value = null;
        selectedNavItem.value = null;
        return;
    }

    activeMobileMenuIndex.value = navItem._uid;
    selectedNavItem.value = navItem;
};

// getter for the active class
const isActive = (navItem) => {
    return activeItems.value[navItem._uid];
};

// close all dropdowns
const closeAllDropDowns = (e) => {
    if (e?.Target.dataset?.dropdown) {
        return;
    }
    Object.keys(activeItems.value).forEach((key) => {
        activeItems.value[key] = false;
    });
};

// class of the icon of font-awesome from the backend
// const menuIconClass = () => {
//     return blok.MobileNavigation.find((item) => item.component === 'MenuIcon').Icon?.icon;
// };

const toggleMobileMenu = () => {
    if (mobileMenuIsActive.value) {
        // Add delay for click events to register
        setTimeout(() => {
            mobileMenuIsActive.value = false;
        }, 100);
        // Add delay for waiting animation done
        setTimeout(() => {
            selectedLevel.value = 'level-1';
            modalStore.enableScroll();
        }, 600);
    } else {
        mobileMenuIsActive.value = true;
        modalStore.disableScroll();
    }
};

const emit = defineEmits(['logout']);

const handleClickMobMenuLink = (event, menuItem) => {
    if (getInternalLink(menuItem?.Link) === '/logout') {
        event.preventDefault();
        emit('logout');
    }
};

const logout = () => {
    emit('logout');
};

const rowsCount = (dropdownItem) => {
    if (dropdownItem.ColumnsCount) {
        return Math.ceil(dropdownItem.Options.length / Number(dropdownItem.ColumnsCount));
    }
    return 0;
};

const topNavClass = (index, dropdownItem) => {
    const formulaResult = rowsCount(dropdownItem);
    return index % formulaResult === 0;
};

const getNavItemStyles = (navItem) => {
    return `
        ${navItem.background?.color ? `background-color: ${navItem.background?.color};` : ''}
        ${navItem.color?.color ? `color: ${navItem.color?.color};` : ''}
    `;
};

const hasLink = (navItem) => navItem?.Link && (navItem?.Link?.url || navItem?.Link?.cached_url);

const isCurrentPageNavItem = (navItem) => navItem?.Link?.url === route.path || navItem?.Link?.cached_url === route.path;
</script>

<style lang="postcss" scoped>
header {
    @apply bg-gray-400 text-gray-900;

    .container {
        @apply flex flex-wrap items-center justify-between;
    }

    .logo {
        @apply h-10 px-4;

        img {
            @apply h-full;
        }
    }

    nav {
        @apply h-full;

        .nav-row {
            @apply items-center h-full;

            .nav-row-item {
                @apply h-full whitespace-nowrap flex items-center;

                &.active {
                    .iconify.i-ri\:arrow-down-s-line {
                        @apply rotate-180;
                    }
                }

                a,
                span:not(.iconify) {
                    @apply flex items-center h-full px-4 text-[#495057] cursor-pointer;
                }

                &.rich-text-menu-item {
                    @apply h-auto;

                    a {
                        @apply block pt-2.5 pb-[5px] px-[15px] h-auto;
                        :deep(p) {
                            @apply m-0 text-inherit;
                        }
                    }
                }
            }

            .mobile-menu {
                display: block;
            }
        }

        .nav-dropdown {
            @apply grid absolute min-w-[10rem] bg-gray-400 z-[100];

            &.right {
                @apply left-auto right-0;
            }

            &.left {
                @apply left-0 right-auto;
            }

            &.center {
                @apply -translate-x-2/4 left-2/4;
            }

            a {
                @apply inline-block py-[0.25rem];

                &:hover {
                    @apply bg-gray-600;
                }
            }
        }
    }
}

.mobile-nav-bg {
    @apply fixed top-0 right-0 left-0 bottom-0 md:hidden z-[4] bg-[#293338] opacity-60;
}

.navigation-button {
    @apply cursor-pointer absolute justify-center items-center right-[0rem] top-[0rem] bottom-[-0.5rem] w-16 text-[1.5rem] text-primary;
}

.mobile-fall-menu {
    @apply hidden z-50 md:hidden absolute inset-x-0;
    background: inherit;

    &.active {
        @apply max-md:block;
    }

    li {
        a {
            @apply px-0.5 py-8 text-center block;
        }

        .iconify {
            @apply relative top-[0.375rem];
        }

        &.rich-text-menu-item {
            a {
                @apply block pt-2.5 pb-[5px] px-[15px] h-auto;
                :deep(p) {
                    @apply m-0 text-inherit;
                }
            }
        }
    }

    .level-wrapper {
        li {
            a {
                @apply px-[15px] py-[7px];
            }
        }
    }
}

.mobile-menu {
    @apply fixed top-0 right-0 transform translate-x-full transition-transform ease-in-out duration-[400ms] bottom-0 w-[75vw] bg-white z-50 h-[calc(100vh)] pl-4 pt-12 overflow-y-scroll;
    box-shadow: -3px -4px 20px 0 rgba(0, 0, 0, 0.1);
    &.slide-left {
        @apply -translate-x-full;
    }

    &.active {
        @apply transform translate-x-0;
    }

    .close-button {
        @apply absolute right-0 top-[10px] z-[1];

        span {
            @apply cursor-pointer flex items-center justify-center w-16 text-[2rem] font-light text-[#243845];
        }
    }

    li {
        @apply border-b border-[#adbfd1] text-[0.875rem] text-[#243845];
        &:empty {
            @apply hidden;
        }

        a,
        span {
            @apply flex items-center p-[1.032rem] pl-0 pr-0 relative;

            > .iconify {
                @apply self-center transition-transform ease-in duration-300 absolute right-4;
            }
        }

        div {
            @apply flex items-center justify-center w-4 mr-2;

            .iconify {
                @apply text-primary h-4;
            }
        }

        .nav-mobile-dropdown {
            @apply hidden;

            &.active {
                @apply block;
            }

            li {
                @apply pl-9;
            }
        }

        &.drop-down-button {
            @apply border-b-0;

            > a {
                @apply w-full flex items-center border-b border-[#adbfd1] pb-4;
            }
        }
    }

    &.new-header {
        @apply w-[75vw] overflow-x-hidden pt-6 pl-6;

        li {
            @apply border-none mb-5;

            div .iconify {
                @apply text-[#293f4d];
            }

            a,
            span {
                @apply py-0 cursor-pointer;

                &:hover {
                    @apply underline;
                }
            }

            .i-ri\:arrow-right-s-line {
                @apply text-[20px];
            }

            &:first-of-type {
                a,
                span {
                    @apply pt-4;
                }
            }

            &.drop-down-button {
                @apply border-b-0;

                > a {
                    @apply border-none;
                }

                .iconify {
                    @apply text-base text-[20px];
                }
            }
        }

        .scroll-wrapper {
            @apply flex overflow-x-hidden;

            .scroll {
                @apply inline-grid grid-cols-[repeat(3,75vw)] transition-[transform] duration-[0.6s];

                > ul > hr {
                    @apply my-5;
                }

                &.level-2 {
                    @apply translate-x-[-75vw];
                }

                &.level-3 {
                    @apply translate-x-[-150vw];
                }

                .level-wrapper,
                > ul {
                    @apply pr-[30px] pt-3;
                }

                .level-wrapper {
                    hr {
                        &.top {
                            @apply mt-6;
                        }

                        &.bottom {
                            @apply mb-3;
                        }
                    }
                }

                .back-button {
                    @apply flex gap-2 cursor-pointer;
                }

                .title {
                    @apply min-h-[48px] flex items-center text-sm font-semibold;
                }
            }
        }
    }
}
</style>
